import "./App.css";
import SubCategory from "./pages/category-sub/SubCategory";
import Category from "./pages/category/Category";
import Home from "./pages/home/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import OfferPage from "./pages/offer-page/OfferPage";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import LogInPage from "./pages/LogInPage/LogInPage";
import SignUpPage from "./pages/LogInPage/SignUpPage";
import ProductDetails from "./pages/product-details/ProductDetails";
import ShoppingCart from "./pages/shopping-cart/ShoppingCart";
import User from "./pages/user/User";
import MainLayout from "./components/main-layout/MainLayout";
import UserLayout from "./components/main-layout/UserLayout";
import CategoriesContextProvider from "./context/categories/provider";
import ProductsContextProvider from "./context/products/provider";
import CartContextProvider from "./context/cart/provider";
import NewArrivalPage from "./pages/home/new-arrival-page/NewArrivalPage";
import FlashDealPage from "./pages/home/flash-deal-page/FlashDealPage";
import { useTranslation } from "react-i18next";
import Test from "./Test";
import PayCard from "./PayCard";
import OrderCard from "./OrderCard";
import MyOrderTest from "./MyOrderTest";
import ObjectTest from "./ObjectTest";
import TestNavbar from "./TestNavbar";
import TestFooter from "./TestFooter";
import TestImageBack from "./TestImageBack";
import CartComponentTest from "./CartComponentTest";
import GoogleLogin from "./GoogleLogin";
import SearchComponent from "./pages/search/SearchComponent";
import NumberVerification from "./pages/LogInPage/NumberVerification";
import OrderDetails from "./pages/user/pages/OrderDetails";
import TestBackground from "./TestBackground";
import HomeFloatingButtons from "./pages/home/HomeFloatingButtons";
import ShopPage from "./pages/shop";
import ScrollToTop from "./components/scroll/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPopNotification } from "./store/actions/homeActions";
import PopNotification from "./pages/home/PopNotification";

function App() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("lang");
  const dipatch = useDispatch();
  const popNotification = useSelector(
    (state: any) => state.home.popNotification
  );

  console.log("popNotification", popNotification);

  // Set a default value if it doesn't exist in local storage
  // if (!localStorage.getItem("currency")) {
  localStorage.setItem("currency", "IQD");
  // }
  if (!localStorage.getItem("language")) {
    localStorage.setItem("language", "en");
  }

  if (searchQuery) {
    localStorage.setItem("language", searchQuery);
  }

  useEffect(() => {
    dipatch(getPopNotification());
  }, []);

  return (
    <div
      style={{
        fontFamily:
          i18n.language == "ar"
            ? "NotoKufiArabic !important"
            : "Makro XM !important",
      }}
    >
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <CartContextProvider>
              <ProductsContextProvider>
                <MainLayout />
              </ProductsContextProvider>
            </CartContextProvider>
          }
        >
          <Route
            index
            element={
              <CategoriesContextProvider>
                <Home />
              </CategoriesContextProvider>
            }
          />
          <Route
            path="/:categoryId"
            element={
              <CategoriesContextProvider>
                <ProductsContextProvider>
                  <Category />
                </ProductsContextProvider>
              </CategoriesContextProvider>
            }
          />
          <Route
            path="/:categoryId/:id"
            element={
              <CategoriesContextProvider>
                <ProductsContextProvider>
                  <SubCategory />
                </ProductsContextProvider>
              </CategoriesContextProvider>
            }
          />
          <Route path="/offer" element={<OfferPage />} />
          <Route path="/new-arrivals" element={<NewArrivalPage />} />
          <Route path="/flash-deal" element={<FlashDealPage />} />
          <Route
            path="/product/:id"
            element={
              <CartContextProvider>
                <ProductsContextProvider>
                  <ProductDetails />
                </ProductsContextProvider>
              </CartContextProvider>
            }
          />
          <Route
            path="/areeba/callback"
            element={
              <CartContextProvider>
                <ShoppingCart index={2} />
              </CartContextProvider>
            }
          />
          <Route
            path="/shopping-cart"
            element={
              <CartContextProvider>
                <ShoppingCart index={0} />
              </CartContextProvider>
            }
          />
          <Route
            path="/search"
            element={
              <CategoriesContextProvider>
                <ProductsContextProvider>
                  <SearchComponent />
                </ProductsContextProvider>
              </CategoriesContextProvider>
            }
          />
          <Route
            path="/shop"
            element={
              <CategoriesContextProvider>
                <ProductsContextProvider>
                  <ShopPage />
                </ProductsContextProvider>
              </CategoriesContextProvider>
            }
          />
          <Route path="/user" element={<User />} />
        </Route>
        <Route>
          <Route path="/login" element={<LogInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/code-check"
            element={<ForgotPassword isRegisterCode />}
          />
          <Route
            path="/numberverification"
            element={<NumberVerification isRegisterCode />}
          />
        </Route>
        <Route path="22" element={<>asd</>} />
        <Route path="/user" element={<UserLayout />}>
          <Route index element={<User />} />
        </Route>
        <Route path="/orders" element={<UserLayout />}>
          <Route index element={<User />} />
        </Route>
        <Route path="/faq" element={<UserLayout />}>
          <Route index element={<User />} />
        </Route>
        <Route path="/terms" element={<UserLayout />}>
          <Route index element={<User />} />
        </Route>
        <Route path="/favorites" element={<UserLayout />}>
          <Route index element={<User />} />
        </Route>{" "}
        <Route path="/orders/:id" element={<UserLayout />}>
          <Route index element={<OrderDetails />} />
        </Route>
        {/* <Route path="/steppertest" element={<StepperTest />} /> */}
        <Route path="/cartcomponenttest" element={<CartComponentTest />} />
        <Route path="/testimageback" element={<TestImageBack />} />
        <Route path="/testfooter" element={<TestFooter />} />
        <Route path="/testnavbar" element={<TestNavbar />} />
        {/* <Route path="/test3d" element={<Test3D />} /> */}
        <Route path="/objecttest" element={<ObjectTest />} />
        <Route path="/myordertest" element={<MyOrderTest />} />
        <Route path="/ordercard" element={<OrderCard />} />
        <Route path="/googlelogin" element={<GoogleLogin />} />
        <Route path="/paycard" element={<PayCard />} />
        <Route path="/testbackground" element={<TestBackground />} />
        <Route path="/test" element={<Test />} />
      </Routes>
      <HomeFloatingButtons />
      {popNotification && (
        <PopNotification
          popNotification={popNotification ? popNotification[0] : {}}
        />
      )}
    </div>
  );
}

export default App;
